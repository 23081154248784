
import Vue from 'vue';
import { mapGetters } from 'vuex';

import BlockHeader from 'UI/BlockHeader.vue';
import theme from 'Theme';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import Icon from 'UI/Icon.vue';
import Guid from 'UI/Guid.vue';
import SwitchControl from 'Control/Switch.vue';
import Account from 'Entities/privatePresenter/Account';
import {
    assignMember,
    getManagedAccountGroups,
    getManagedAccountSelectedGroup, unAssignMember,
} from 'Store/v2/Groups';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import Group from 'Entities/privatePresenter/Group';
import GroupMemberResponse from 'Entities/groupManagement/GroupMemberResponse';
import GroupUserInfo from 'Entities/groupManagement/GroupUserInfo';
import Button from 'Control/Button.vue';
import Select from 'Control/Select.vue';

interface Data {
    theme: typeof theme;
    activeGroupIndex: number;
    newUserPolicies: string[];
    selectedUser: string;
    editingUserIndex: null | number;
    editableUserPolicies: string[];
}

interface Methods {
    goBack: () => void;
    onSelect: (data: { item: string; index: number; }) => void;
    onChange: (data: string) => void;
    onSelectUser: (data: string) => void;
    invite: () => void;
    deleteUser: (data: GroupUserInfo) => void;
    editMember: (data: number) => void;
    onEditPolicy: (data: string) => void;
    cancelEditing: () => void;
    saveEditing: () => void;
    renewGroupsData: () => void;
    getSwitchValue: (data: { index: number, member: GroupUserInfo, policy: string }) => boolean;
    getIsSwitchDisabled: (data: { index: number, policy: string }) => boolean;
    isCurrentUserInMembers: (data: GroupUserInfo) => boolean;
}

interface Computed {
    availablePolicies: string[];
    currentUser: InternalUserResponse | undefined;
    fullName: string;
    activeAccount: Account;
    activeAccountPolicies: string[];
    hasAvailablePolicies: boolean;
    groups: Group[];
    groupsNames: string[];
    selectedGroup: GroupMemberResponse | null;
    activeAccountId: string;
    accountGroupMembers: GroupUserInfo[];
    membersToChoose: GroupUserInfo[];
    isActiveAccountAssigned: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        BlockHeader,
        Icon,
        Guid,
        SwitchControl,
        Button,
        Select,
    },
    data() {
        return {
            theme,
            activeGroupIndex: 0,
            newUserPolicies: [],
            selectedUser: '',
            editingUserIndex: null,
            editableUserPolicies: [],
        };
    },
    computed: {
        ...mapGetters({
            availablePolicies: 'Accounts/availablePolicies',
            activeAccount: 'Accounts/activeAccount',
            hasAvailablePolicies: 'Accounts/hasAvailablePolicies',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        activeAccountPolicies() {
            return this.activeAccount?.policies ?? [];
        },
        fullName() {
            if (!this.currentUser) {
                return '';
            }
            return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
        },
        groups() {
            return this.$store.state.Groups.managedAccountGroups;
        },
        groupsNames() {
            return ['Select Institutional Group', ...this.groups.map(({ name }) => name)];
        },
        selectedGroup() {
            return this.$store.state.Groups.managedAccountSelectedGroup;
        },
        accountGroupMembers() {
            return this.selectedGroup?.members?.filter((member) => {
                return this.isCurrentUserInMembers(member) && member.uid !== this.currentUser?.id;
            }) ?? [];
        },
        membersToChoose() {
            return this.selectedGroup?.members?.filter((member) => {
                return member.uid !== this.currentUser?.id && !this.isCurrentUserInMembers(member);
            }) ?? [];
        },
        isActiveAccountAssigned() {
            return this.groups.some(({ accounts }) => accounts?.some(({ accountId }) => accountId === this.activeAccountId));
        },
    },
    methods: {
        isCurrentUserInMembers(member) {
            return member.assignedAccounts?.some((account) => {
                return account.accountId === this.activeAccountId;
            }) ?? false;
        },
        goBack() {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/terminal').catch(() => { /* navigation error */ });
            }
        },
        onSelect({ index }) {
            this.activeGroupIndex = index;
            this.selectedUser = '';
        },
        onChange(policy) {
            const index = this.newUserPolicies.findIndex((p) => p === policy);
            if (index !== -1) {
                this.newUserPolicies.splice(index, 1);
            } else {
                this.newUserPolicies.push(policy);
            }
        },
        onSelectUser(userFullName) {
            this.selectedUser = userFullName;
        },
        async invite() {
            try {
                if (!this.selectedGroup) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Select a group');
                    return;
                }
                if (!this.selectedUser) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Select user to send an invitation');
                    return;
                }
                if (this.newUserPolicies.length === 0) {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Select at least one policy');
                    return;
                }
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(assignMember({
                    groupId: this.selectedGroup?.id ?? '',
                    accountId: this.activeAccountId,
                    userId: this.membersToChoose.find(({ lastName, firstName }) => `${firstName} ${lastName}` === this.selectedUser)?.uid ?? '',
                    policies: this.newUserPolicies,
                }));
                this.selectedUser = '';
                this.newUserPolicies = [];
                await this.renewGroupsData();
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Invitation was successfully sent');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error inviting user');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async deleteUser(member) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(unAssignMember({
                    groupId: this.selectedGroup!.id!,
                    userId: member.uid!,
                    accountId: this.activeAccountId,
                }));
                await this.renewGroupsData();
                await this.$store.dispatch('Notificator/showSuccessNotification', 'User was successfully deleted');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error deleting user');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        editMember(index) {
            const account = this.accountGroupMembers[index].assignedAccounts!.find(({ accountId }) => accountId === this.activeAccountId);
            if (account) {
                this.editableUserPolicies = account.policies!;
                this.editingUserIndex = index;
            }
        },
        onEditPolicy(policy) {
            const index = this.editableUserPolicies.findIndex((p) => p === policy);
            if (index !== -1) {
                this.editableUserPolicies.splice(index, 1);
            } else {
                this.editableUserPolicies.push(policy);
            }
        },
        async cancelEditing() {
            this.editingUserIndex = null;
            this.editableUserPolicies = [];
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.renewGroupsData();
            } catch (error) {
                await this.$router.push('/terminal').catch(() => { /* navigation error */ });
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async saveEditing() {
            if (this.editableUserPolicies.length === 0) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Select at least one policy');
                return;
            }
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(assignMember({
                    groupId: this.selectedGroup?.id ?? '',
                    accountId: this.activeAccountId,
                    userId: this.accountGroupMembers[this.editingUserIndex!].uid ?? '',
                    policies: this.editableUserPolicies,
                }));
                await this.cancelEditing();
                await this.$store.dispatch('Notificator/showSuccessNotification', 'User was successfully edited');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error editing user policies');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async renewGroupsData() {
            await this.$store.dispatch(getManagedAccountGroups(undefined));
            const activeGroupName = this.groupsNames[this.activeGroupIndex];
            const group = this.groups.find(({ name }) => name === activeGroupName);
            if (group) {
                await this.$store.dispatch(getManagedAccountSelectedGroup(group.id));
            }
        },
        getSwitchValue({ index, member, policy }) {
            // if is editing get value from editableUserPolicies else from network data
            return (this.editingUserIndex === index ? this.editableUserPolicies.includes(policy) : member.assignedAccounts?.find(({ accountId }) => accountId === this.activeAccountId)?.policies?.includes(policy)) ?? false;
        },
        getIsSwitchDisabled({ index, policy }) {
            // user only could switch editable policies, but institution is disabled forever
            return (this.editingUserIndex !== index || policy === 'institution' || !this.activeAccountPolicies.includes(policy));
        },
    },
    async mounted() {
        if (!this.hasAvailablePolicies) {
            await this.$store.dispatch('Accounts/downloadAvailablePolicies');
        }
        try {
            this.$store.commit(SET_LOADING_ON(undefined));
            await this.$store.dispatch(getManagedAccountGroups(undefined));
            if (this.isActiveAccountAssigned) {
                const assignedGroup = this.groups.find(({ accounts }) => accounts!.find(({ accountId }) => accountId === this.activeAccountId));
                const index = this.groupsNames.findIndex((name) => name === assignedGroup!.name);
                this.onSelect({ index, item: '' });
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting groups');
            }
        } finally {
            this.$store.commit(SET_LOADING_OFF(undefined));
        }
    },
    watch: {
        async currentUser(value) {
            if (value) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch(getManagedAccountGroups(undefined));
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting groups');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        async activeGroupIndex(value) {
            const activeGroupName = this.groupsNames[value];
            const group = this.groups.find(({ name }) => name === activeGroupName);
            if (group) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch(getManagedAccountSelectedGroup(group.id));
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting group info');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
                return;
            }
            await this.$store.dispatch(getManagedAccountSelectedGroup(null));
        },
        async activeAccount(value) {
            this.editingUserIndex = null;
            this.editableUserPolicies = [];
            await this.cancelEditing();
            if (value.isOwned || !value.policies.includes('institution')) {
                await this.$router.push('/terminal').catch(() => { /* navigation error */ });
            } else {
                try {
                    this.newUserPolicies = [];
                    this.selectedUser = '';
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch(getManagedAccountGroups(undefined));
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error getting groups');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
        isActiveAccountAssigned(value) {
            if (value) {
                const assignedGroup = this.groups.find(({ accounts }) => accounts!.find(({ accountId }) => accountId === this.activeAccountId));
                const index = this.groupsNames.findIndex((name) => name === assignedGroup!.name);
                this.onSelect({ index, item: '' });
            }
        },
    },
});
